.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.form-group label {
  flex: 0 0 150px;
  margin-right: 10px;
}

.form-group input,
.form-group select {
  flex: 1;
  padding: 5px;
}
.form-flex-container {
  display: 'flex';
  flex-direction: column;
}
.form-flex {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: 3%;
  margin-bottom: 5px;
  gap: 10px;
}
.form-grid {
  display: grid;
  padding: 7px;
  gap: 10px;
}
.form-flex label {
  float: left;
  text-align: right;
  margin-top: 3px;
  width: 150px;
  font-size: 12px;
  color: #363463;
}
.form-flex input,
.form-flex select {
  padding: 2px;
  border: 1px solid #adacac;
  border-radius: 4px;
}
.medintervals {
  font-size: 10px;
  max-width: 200px;
  word-wrap: break-word;
}
.createvisit-submitbtn {
  background-color: #4682b4;
  width: 20%;
  color: white;
  margin-left: 42%;
  margin-top: 2%;
}
.lab-rad-table {
  max-height: 350px;
  overflow-y: auto;
}
.opdpatientdata {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #090968;
  font-size: 15px;
}
.patient-details-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.fixed-section {
  flex: 0 0 auto;
}

.scrollable-section {
  flex: 1 1 auto;
  overflow-y: auto;
}
.opd-table {
  font-size: 15px;
  cursor: pointer;
}
.close-button {
  display: flex;
  justify-content: end;
  margin-right: 1rem;
  cursor: pointer;
}
.mtminus4 {
  margin-top: -4px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.h-20 {
  height: 20px;
}
.sl-wd {
  width: 3rem;
}
.service-wd {
  width: 4rem;
}

.ratingIcon {
  color: #183f7c;
}

.statusCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lab-radiology-tab {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.lab-radiology-table {
  font-size: 15px;
  cursor: pointer;
}
.VisitNewTable {
  margin-top: -1rem;
  margin-left: -1rem;
}
.TableHeadFixed {
  position: sticky;
  top: 0px;
  z-index: 9;
}
.EmptyAppointmentText {
  text-align: center;
}
