.membershipDetailCard {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}
.typeCardOne {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
}
.dateCard {
  display: flex;
  gap: 35px;
  margin-left: 155px;
}
.selected-name {
  color: #004c91;
  text-decoration: underline;
}
.dateCardOne {
  font-weight: 600;
  color: #787878;
  font-size: 12px;
}
.membershipProfileCard {
  border: 1px solid #004c91;
  border-radius: 5px 5px 0 0;
  margin-top: 0;
  margin-left: 100px;
  margin-bottom: 15px;
  background-color: #dce0fc;
  padding: 10px;
  position: relative;
  box-shadow: 1px 1px 12px #0000001f;
  width: 50%;

  p {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 14px;
  }

  div {
    padding-left: 58px;

    p {
      span {
        text-transform: capitalize;
      }
    }
  }

  img {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 44px;
  }
}
.user {
  display: flex;
  gap: 13px;
}
.name {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
body,
table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.members-table {
  width: 65%;
  border-collapse: collapse;
  background-color: #96babb14;
}

.members-table th,
.members-table td {
  padding: 10px;
  text-align: left;
}
.members-table td {
  border-bottom: 1px solid black;
  font-size: 14px;
}
.members-table tr:last-child {
  border-bottom: none;
}

.members-table th {
  font-weight: bold;
}

.membersDiv {
  width: 80%;
  margin: 12px auto;
}
.type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-left:20%
}
.nameOne {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.nameTwo {
  font-size: 12px;
  color: #5d5959;
  font-weight: 600;
}
.typeOne {
  margin-top: 5px;
  padding: 0.8em;
}
.typeButton {
  background-color: #004c91;
  color: #fff;
  cursor: pointer;
}
.typeCard {
  display: flex;
  gap: 10px;
}
.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  pointer-events: none;
}
.patient {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.patients-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;
}
.patient-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tabFixHead {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.search {
  display: flex;
  gap: 0.5rem;
}
.search-input {
  padding-left: 6px;
}
.dateContainer {
  font-size: 15px;
}
.memberId {
  font-weight: 600;
}
.editPatient {
  width: 46px;
  background-color: #090968;
}
.editImage {
  width: 32px;
}
.editor {
  margin-top: 10px;
  margin-left: 10px;
}
.typeButtons {
  background-color: #004c91;
  color: #fff;

  cursor: pointer;
  padding: 1px 6px;
}
.flexDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.overallContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.flexDivs {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.flexDivsOne {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}
.layoutcontent {
  padding: 0.8rem;
}

.doctorSelected {
  width: 17%;
  display: block;
  padding: 6px 6px 6px 9px;
  border: 1px solid #004c91;
  border-radius: 4px;
  background-color: transparent;
}
.selectable-search-wrapper {
  position: relative;

  .search-input {
    border: 1px solid #aeafb5;
    width: 300px;
    padding: 4px;
    border-radius: 4px;
    height: 30px;

    &::placeholder {
      font-size: 13px;
      font-style: italic;
    }
  }
}
.fixedTableHeads {
  overflow: auto;
  max-height: 256px;
}
.createbutton {
  border: 0;
  padding: 6px 9px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 1px 1px 12px #0000001f;
  cursor: pointer;
  background-color: #004c91;
  color: white;
}
.checkbox-label.checked input[type='checkbox'] {
  background-color: blue;
}
.wallet {
  font-size: 15px;
  font-weight: 600;
}
.category {
  margin-bottom: 20px;
}
.membershipText {
  margin-bottom: -1.5rem;
  font-weight: 600;
  font-size: 15px;
  color: #090968;
}
.pointer {
  cursor:pointer
}
.backspace-icon-membership-view {
  font-size: 20;
  color: blue;
  margin-top: -8px;
}
.membership-icon-btn {
  margin-top: 2px;
   margin-left: 8px
}
.dashboard-container {
  display:flex
}
