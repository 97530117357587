.custom-header {
    display: flex;
    align-items: center;
    gap:10px
  }
  
  .ag-header-cell-menu-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 8px; /* Add space between display name and filter icon */
  }
  
//   .filter-icon {
//     color: white; /* Ensure visibility */
//     font-size: 20px; /* Adjust size to match design */
//   }
  .with-red-dot {
    position: relative; /* Enables positioning of the pseudo-element */
    font-size: 10px; /* Adjust size to match your layout */
    color: white; /* Icon color */
  }
  
//   .with-red-dot {
//     content: '';
//     position: absolute;
//     top: -4px; /* Position the dot above the icon */
//     right: -4px; /* Adjust horizontally as needed */
//     width: 8px; /* Size of the red dot */
//     height: 8px; /* Size of the red dot */
//     background-color: red; /* Color of the dot */
//     border-radius: 50%; /* Makes the dot circular */
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow */
//   }
  
// .filter-icon-container {
//     position: relative;
//     display: inline-block; /* Ensures the container wraps tightly around the icon */
//   }
  
//   .filter-icon {
//     font-size: 24px; /* Adjust as needed */
//   }
  
//   .filter-icon-container::after {
//     content: '';
//     position: absolute;
//     top: 0; /* Adjust for precise positioning */
//     right: 0;
//     width: 8px; /* Size of the red dot */
//     height: 8px;
//     background-color: red;
//     border-radius: 50%; /* Makes it a perfect circle */
//     border: 2px solid white; /* Optional: to give a border for better visibility */
//     transform: translate(50%, -50%); /* Adjusts the dot's position */
//   }
  .filter-icon-container {
    position: relative;
    display: inline-block; /* Ensures the container wraps tightly around the icon */
    top:3px
  }
  
  .filter-icon {
    font-size: 19px; /* Adjust as needed */
   // color:black;
    color: rgba(255, 255, 255, 0.6); /* Make the icon semi-transparent */
  background: transparent;
  }
  
  /* Red dot appears only if 'with-red-dot' class is added */
  .with-red-dot::after {
    content: '';
    position: absolute;
    top: 0; /* Adjust for precise positioning */
    right: 0;
    width: 8px; /* Size of the red dot */
    height: 8px;
    background-color: red;
    border-radius: 50%; /* Makes it a perfect circle */
    border: 2px solid white; /* Optional: to give a border for better visibility */
    transform: translate(50%, -50%); /* Adjusts the dot's position */
  }
  