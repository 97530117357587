.admin-container {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  height: 96%;
}

.admin-menu {
  flex: 0 0 200px;

  ul {
    padding-left: 0;
    margin: 0;
    background-color: #0d3f6d;

    li {
      list-style-type: none;
      border-bottom: 1px solid #e6e6e6;

      &:last-child {
        border-bottom: none;
      }

      a {
        font-size: 13px;
        color: #fff;
        text-decoration: none;
        padding: 8px;
        display: block;
        transition: 0.2s;

        &.active {
          background-color: #f8f8f8;
          color: #115797;
        }
      }
    }
  }
}

.admin-content {
  flex-grow: 1;
  padding: 12px 8px;
}

.button-enclosure {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;

  .admin-search {
    margin-right: 6px;
    border: 1px solid #aeafb5;
    width: 300px;
    padding: 4px;
    border-radius: 4px;
    height: 30px;

    &::placeholder {
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
    }
  }
}

.back-button {
  margin-bottom: 12px;
  background-color: transparent;
  color: #000000;
  padding: 6px 14px;
  font-size: 14px;
  border: 1px solid #004c91;

  &:hover {
    background-color: #004c91;
    color: #ffffff;
    cursor: pointer;
  }
}

.admin-form {
  label {
    font-size: 13px;
    font-weight: 500;
    margin: 4px 0;
  }

  input[type='text'],
  textarea,
  select,
  input {
    padding: 2px 10px;
    border: 1px solid #ddd;
    margin-bottom: 4px;
    margin: 5px 0 0;
    min-width: 255px;
    font-size: 13px;

    &:focus-visible {
      outline: #0d3f6d auto 1px;
    }
  }

  textarea {
    resize: none;
  }

  button {
    margin-top: 12px;
    background-color: #004c91;
    padding: 8px 18px;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 6px;
    border: none;
  }
}
.panel-form {
  label {
    font-size: 13px;
    font-weight: 500;
    margin: 4px 0;
  }

  input[type='text'],
  textarea,
  input {
    padding: 2px 10px;
    border: 1px solid #ddd;
    margin-bottom: 4px;
    margin: 5px 0 0;
    // min-width: 100px;
    min-width: 149px;
    max-width: 225px;
    font-size: 13px;

    &:focus-visible {
      outline: #0d3f6d auto 1px;
    }
  }
  select {
    padding: 2px 10px;
    border: 1px solid #ddd;
    margin-bottom: 4px;
    margin: 5px 0 0;
    min-width: 100px;
    max-width: 225px;
    font-size: 13px;
  }

  textarea {
    resize: none;
  }

  button {
    margin-top: 12px;
    background-color: #004c91;
    padding: 8px 18px;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 6px;
    border: none;
  }
}

.admin-table-wrapper {
  overflow: auto;
  max-height: 500px;
}
.admin-table-wrapperDocter {
  overflow: auto;
  max-height: 400px;
}

.admin-table-body {
  overflow: auto;
}
.admin-form-flex {
  display: flex;
  flex-direction: column;
}
.main-container {
  display: flex;
  flex-direction: row;
  gap: 17%;
  flex-wrap: wrap;
}
.admin {
  width: 140px;
  height: 140px;
}
.admin-upload {
  width: 38px;
}
.picture-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex-wrap: wrap;
}
.flexible {
  justify-content: space-between !important;
}
.filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.filter-label {
  margin-top: 10px;
}
.admin-label {
  display: 'flex';
  align-items: 'center';
}
.table-container {
  overflow-y: 'auto';
}
.admin-table-head.sticky {
  position: sticky;
  top: 0;
}
.wordBreak {
  word-break: break-all;
}
.normal {
  white-space: normal;
}
.edit-relation {
  position: 'relative';
}
.status-filter {
  height: 30px;
  width: 60px;
}
.overflow-y {
  overflow-y: auto;
}
.position-top {
  position: 'sticky';
  top: '0px';
}
.flex-center {
  display: flex;
  align-items: center;
}
.border-none {
  display: flex;
  border: none !important;
}
.filterandtext {
  display: flex;
  gap: 1rem;
}
.status-flex {
  display: flex;
  gap: 10px;
}
.mr-none {
  margin-right: none !important;
}
.header-bottom {
  margin-bottom: 7px;
  margin-top: -9px;
}
.mtminus8 {
  margin-top: -8px;
}
.mb9 {
  margin-bottom: 9px !important;
}
.mb6 {
  margin-bottom: 6px;
}
.mr10 {
  margin-right: 10px;
}
.doctor-status {
  border-radius: 5px;
  background: white;
  border: 1px solid #aeafb5;
}
.h30 {
  height: 30px;
}
.doc-container {
  display: flex;
  gap: 10px;
}
.doc-input-label {
  flex: 0 0 50%;
  display: flex;
  justify-content: end;
}
.doc-input-content {
  flex: 0 0 50%;
}
.panel-input-content {
  flex: 0 0 81%;
}
.pathology-container {
  display: flex;
  gap: 10px;
}
.pathology-input-label {
  flex: 0 0 50%;
  display: flex;
  justify-content: end;
}
.pathology-input-content {
  flex: 0 0 50%;
}
.location-container {
  display: flex;
  gap: 10px;
}
.location-input-label {
  flex: 0 0 50%;
  display: flex;
  justify-content: end;
}
.location-input-content {
  flex: 0 0 50%;
  max-width: 255px;
}
.submit-btn-pathology {
  padding: 4px 6px !important;
  text-transform: none;
  max-width: 100px;
}
.submit-btn-location {
  padding: 4px 6px !important;
  text-transform: none;
  max-width: 100px;
}
.submit-btn-doctor {
  padding: 4px 6px !important;
  text-transform: none;
  max-width: 100px;
}
.box-btn-pathology {
  display: flex;
  justify-content: center;
}
.box-btn-doctor {
  display: flex;
  justify-content: center;
}
.box-btn-panel {
  display: flex;
  // justify-content: center;
}
.box-btn-referred {
  display: flex;
  // justify-content: center;
}
.box-btn-location {
  display: flex;
  justify-content: center;
}
.back-btn-admin {
  font-size: 20px;
  color: blue;
  margin-top: -8px;
}
.w-72 {
  width: 72%;
}
.partner-table,
.partner-table tr,
.partner-table td,
.partner-table th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}

.partner-table th {
  font-weight: 500;
  background-color: #4682b4;
  color: white;
  font-size: 12px !important;
}

.partner-table th,
.partner-table td {
  padding: 7px;
  font-size: 12px;
}
.loc-submit-btn {
  width: 10%;
}
.w100 {
  width: 100%;
}
.w10 {
  width: 10%;
}
.w72 {
  width: 72%;
}
.partner-container {
  display: flex;
}
.partner-submit-btn {
  width: 10%;
  background-color: #4682b4 !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-transform: capitalize !important;
}
.radiologist-back-btn {
  margin-top: 3px;
}
.mt4 {
  margin-top: '4px';
}
.SubmitPanelForm {
  width: 10%;
  background-color: #4682b4;
  color: #ffffff;
}
.lab-pathology-select {
  width: 100%;
}
.lab-pathology-select-div {
  width: 100%;
  padding: 0 1% 0 1%;
}
.lab-pathology-approve-selectDiv {
  width: 80%;
}
.set-action-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.odooCheckBox {
  height: 15px;
}
