@import url('./assets/css/style.scss');
body {
  background-color: #f8f8f8;
}

.ag-theme-alpine .ag-header-cell.custom-header-cell {
  // background-color: #4682b4 !important;
  color: #ffffff !important;
}

// .ag-theme-alpine .ag-header-cell.custom-header-cell:hover {
//   background-color: #4682b4 !important;
// }

.ag-theme-alpine .ag-header {
  background-color: #4682b4 !important;
  // --ag-header-foreground-color: #ffffff;
}

.ag-theme-alpine .ag-icon-menu:before,
.ag-theme-alpine .ag-icon-asc:before,
.ag-theme-alpine .ag-icon-desc:before,
.ag-theme-alpine .ag-icon-filter:before {
  color: #ffffff;
}

.ag-theme-alpine {
  --ag-row-border-style: solid !important;
  --ag-row-border-width: 1px !important;
  --ag-row-border-color: #e6e6e6 !important;
  --ag-cell-horizontal-border: 1px solid #e6e6e6 !important;
}
