.mainCard {
  border: 1px solid #004c91;
  border-radius: 5px 5px 0 0;
  box-shadow: 1px 1px 12px #0000001f;

  h3 {
    margin: 0;
    font-size: 14px;
    background-color: #4682b4;
    color: #fff;
    font-weight: 400;
    padding: 6px 12px;
  }

  .cardContent {
    .singleCard {
      border-bottom: 1px solid #f5f5f5;

      .singleCardLabel {
        position: relative;
        background-color: #dce0fc;
        cursor: pointer;

        h6 {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          padding: 6px 0 6px 25px;
        }

        span {
          position: absolute;
          left: 10px;

          img {
            height: 10px;
            transform: rotate(-90deg);
          }
        }

        .status_by_on {
          position: absolute;
          font-size: 13px;
          right: 6px;
          top: 6px;
          margin: 0;
          color: #7d7d7d;
          font-style: italic;
        }
      }
    }
  }
}

.expandingCard {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.expandedContent {
  p {
    margin: 0;
    font-size: 13px;
    border-bottom: 1px solid #f5f5f5;
    padding: 6px;
    padding-left: 15px;
    color: #363463;
  }
}

.activeExpanded {
  .expandingCard {
    height: auto;
  }

  h6 {
    span img {
      transform: rotate(0deg) !important;
    }
  }
}

.patientProfileCard {
  border: 1px solid #004c91;
  border-radius: 5px 5px 0 0;
  margin-top: 0;
  margin-left: 100px;
  margin-bottom: 15px;
  background-color: #dce0fc;
  padding: 10px;
  position: relative;
  box-shadow: 1px 1px 12px #0000001f;
  width: 50%;

  p {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 14px;
  }

  div {
    padding-left: 58px;

    p {
      span {
        text-transform: capitalize;
      }
    }
  }

  img {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 44px;
  }
}

.printButton {
  position: absolute;
  right: 100px;
  top: 18px;
  padding-left: 0 !important;

  img {
    position: unset;
    width: 20px;
    cursor: pointer;
  }
}

.caseSheetButton {
  position: absolute;
  right: 60px;
  top: 18px;
  padding-left: 0 !important;

  img {
    position: unset;
    width: 20px;
    cursor: pointer;
  }
}

.e-prescriptionBtn {
  position: absolute;
  right: 20px;
  top: 18px;
  padding-left: 0 !important;

  img {
    position: unset;
    width: 20px;
    cursor: pointer;
  }
}

.uploadDocBtn {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  button {
    cursor: pointer;
  }

  img {
    position: unset;
    width: 30px;
    cursor: pointer;
  }
}

.docViewBody {
  display: flex;
  flex-direction: column;
}

.trplangrid {
  margin-top: '0.5px';
  margin-bottom: '10px !important';
}

.docUploadBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 5px;

  img {
    position: unset;
    width: 18px;
    cursor: pointer;
  }
}

.img-grid-view {
  flex-basis: 42%;
  margin-bottom: 20px;
  margin-top: 10px;
  iframe {
    margin-top: 10px;
    border: 1px solid grey;
    padding: 10px;
  }
}

.active {
  button {
    background-color: #115797;
    color: white;
  }
}

.docView {
  border-top: 1px solid rgb(18, 102, 180);
  padding: 5px;
  flex-grow: 3;
  min-height: 300px;
  min-width: 500px;
}

// ----------------
.docListView {
  list-style-type: none;
  padding: 0;
}

.docListItem {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.docItemSerial {
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.docItemInfo {
  display: flex;
}

.docItemTitle {
  margin-right: 10px;
  font-size: 12px;
}

.docItemButtons {
  display: flex;
}

.docItemButton {
  margin-right: 5px;
  padding: 0px 8px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.docItemButton:hover {
  background-color: #004c91;
}

//model css
.document-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.document-modal.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-modal-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  width: 800px;
  height: 600px;
  background-color: #fff;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.close-modal-button:hover {
  background-color: #999;
}

.dashboard-headContent {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.tableContent {
  p {
    width: 50%;
    display: inline-block;

    &:first-child {
      text-align: right;
      padding-right: 40px;
    }
  }
}

.wleft {
  margin-right: 10px;
}

.wright {
  margin-left: 10px;
}

.dateSelections {
  margin-bottom: 20px;
  width: 100%;
  padding: 0 3px;

  span {
    background-color: #eee;
    border: 1px solid #ccc;
    color: #747474;
    padding: 5px;
    display: inline-block;
    text-align: center;
    font-size: 9px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    width: 104px;
    height: 50px;

    &:hover {
      border: 1px solid #ccc;
      background-color: #ccc;
    }
  }

  .activeDateTab {
    background-color: #004c91;
    border: 1px solid #004c91;
    color: #fff;
    width: 104px;
    height: 50px;
  }

  button {
    background: linear-gradient(to bottom, #fff, #ddd);
    background-color: #ddd;
    border: 1px solid #ddd;
    padding: 9px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    height: 42px;
    margin: 0 3px;

    &:hover {
      background: linear-gradient(to bottom, #ddd, #fff);
    }

    img {
      width: 14px;
    }
  }

  .lBtn {
    img {
      transform: rotate(90deg);
    }
  }

  .rBtn {
    img {
      transform: rotate(-90deg);
    }
  }
}

@media screen and (max-width: 1000px) {
  .patientProfileCard {
    width: 100%;
  }

  .sx-screen-noFlex {
    display: block;

    .widthP50 {
      width: 100%;
    }

    .wright {
      margin-left: 0;
    }
  }
}

.react-multi-carousel-item {
  min-width: 105px;
  max-width: 105px;
  max-height: 50px;
}

.react-multi-carousel-list {
  position: relative;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
  top: 0;
  border-radius: 8px !important;
  min-width: 25px !important;
  min-height: 25px;
  background-color: #0d3f6d85 !important;

  &:hover {
    background-color: #0d3f6d !important;
  }
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
  top: 0;
  border-radius: 8px !important;
  min-width: 30px !important;
  min-height: 25px;
  background-color: #0d3f6d85 !important;

  &:hover {
    background-color: #0d3f6d !important;
  }
}

.regCardBody {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  @page :left {
    margin: 0;
  }

  @page :right {
    margin: 0;
  }
}

.ribbon {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
}

.headerRibbon {
  display: flex;
}

.footerOpcaseSheet {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footerLeft {
  background-color: #f47421;
  height: 40px;
  flex: 1.142;
  margin-left: -55px;
  padding-left: 5%;

  p {
    font-size: 12px;
    width: 250%;
    // margin-top: 15px;
    // margin-left: 45px;
  }
}

.footerRight {
  background-color: #004d91;
  height: 40px;
  flex: 2;
  margin-right: -65px;
  padding-left: 5%;

  p {
    font-size: 12px;
    margin-bottom: 0;
    // margin-top: 15px;
    margin-left: 80px;
    color: #ffffff;
  }
}

.orangeline {
  background-color: #f47421;
  height: 7px;
  flex: 1.2;
}

.blueline {
  background-color: #004d91;
  height: 7px;
  flex: 2;
}

.RegCardMain {
  margin-left: 5%;
  margin-right: 5%;
}

.regCardHeader {
  display: flex;
}

.medUniLogo {
  flex: 1;

  img {
    width: 218px;
    margin-top: 20px;
  }
}

.medUniAdd {
  flex: 3;
  text-align: right;
  margin-top: 15px;

  img {
    width: 10px;
    vertical-align: -2px;
    top: 21px;
    left: 50%;
  }

  p {
    display: inline-block;
    font-family: Roboto;
    font-size: 12px;

    font-weight: 400;
    line-height: 0px;
    margin-top: 16px;
    margin-right: -5px;
  }
}

.title-body {
  position: relative;
  padding-bottom: 10px;
}

.title-main {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration-line: underline;
}

.barcode-style {
  position: absolute;
  right: -6px;
  top: 50%;
  width: 20%;
  transform: translate(-15%, -50%);
}

.patientDetail {
  display: flex;
  margin-top: 10px;
}

.tableBorderLeft {
  border: 1px solid #cbcbcb;
  width: 51%;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 60px;
  flex-direction: column;
}

.patient-main {
  display: flex;

  p {
    margin-bottom: 0;
    width: 30%;
    font-size: 14px;
  }

  span {
    // margin-bottom: 0;
    // text-align: left;
    margin-top: 15px;
    margin-left: -80px;
    // width: 50%;
    font-size: 14px;
  }
}

.tableBorderRight {
  border: 1px solid #cbcbcb;
  width: 49%;
  justify-content: space-around;
  display: flex;

  img {
    width: 65%;
    filter: grayscale(1);
    align-self: center;
  }
}

.footer-style {
  position: fixed;
  bottom: 0;
  width: 100%;

  footer {
    background-color: #e8e8e8;
  }

  p {
    font-size: 13px;
    line-height: 40px;
    text-align: right;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.opCaseSheetMain {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  table {
    width: 100%;

    thead th {
      height: 100px;
    }
  }
}

.tableBody {
  padding-left: 5%;
  padding-right: 5%;
}

.tableBody-Head {
  display: flex;
  margin-bottom: -22px;
  margin-top: -80px;
  position: relative;
}

.addressArea {
  flex: 2;
  text-align: right;

  p {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    line-height: 0px;
    margin-top: 16px;
  }
}

.mobileNoAddress {
  display: flex;
  justify-content: flex-end;

  div p {
    margin-left: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
  }
}

.emailAddress {
  p {
    margin-left: 3px;
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
    line-height: 11px;
  }
}

.barCodeOpcaseSheet {
  margin-top: 15px;
}

.titleOpcaseSheet {
  position: absolute;
  bottom: -10px;
  width: 100%;

  h4 {
    font-size: 24px;
    text-align: center;
    color: #004d91;
  }
}

.epresTitle {
  h4 {
    font-size: 24px;
    text-align: center;
    color: #004d91;
  }
}

.horizontalLine {
  border: 1px solid #004d91;
  margin-bottom: 20px;
}

.horizontalLineOP {
  border: 1px solid #004d91;
  margin-bottom: 20px;
}

.flexOne {
  flex: 1;
}

.flexPointSeven {
  flex: 0.7;
}

.patientUHID {
  p {
    font-style: normal;
    font-size: 15px;
  }
}

.patientUHIDValue {
  flex: 1;
  margin-top: 15px;
  margin-right: 35%;

  input {
    height: 32px;
    font-weight: 600;
    text-align: justify;
    text-transform: uppercase;
    padding-left: 10px;
  }
}

.wongPainScoreLine {
  color: #004d91;
  font-size: 18px;
  margin-top: 5%;
}

.wongPainScoreParam {
  display: flex;
  text-align: center;
  margin-top: 5%;
}

.width30 {
  width: 30%;
}

.pText {
  font-style: normal;
  font-size: 15px;
}

.pTextInput {
  height: 30px;
  width: 50px;
}

.subTitleOpSheet {
  font-style: normal;
  font-size: 15px;
}

.caseSheetSummery {
  font-size: 14px;
  display: inline-block;
  height: 190px;
}

.medication-table {
  width: 100%;
  border-collapse: collapse;
}

.medication-cell-head {
  padding: 8px;
  height: 10px !important;
  text-align: left;
  border: 1px dashed #e0e0e0;
}

.medication-cell {
  padding: 3px 8px;
  text-align: left;
  border: 1px dashed #e0e0e0;
}

// .caseSheetSummery:empty::before {
//     content: "\200b";
//     /* unicode zero width space character */
// }

.pHistorySec {
  display: flex;
  // margin-top: 25px;
}

.pHistory {
  color: #004d91;
  font-size: 18px;
}

.pHistoryMenu {
  font-size: 15px;
  // margin-top: 30px;
  font-style: normal;
}

.marginTop30 {
  margin-top: 30px;
}

.pDataCheckBox {
  flex: 1;
  margin-top: 12px;

  input {
    width: 40px;
    height: 22px;
  }
}

.pSummary {
  margin-left: 10px;
  // margin-top: 5px;
}

.font13px {
  font-size: 13px;
}

.otherHistoryBox {
  border: 1px solid #ccc;
  height: 90px;
  margin-top: 25px;

  p {
    margin-top: 10px;
  }
}

.familyHistoryOp {
  color: #004d91;
  font-size: 17px;
  max-height: 2%;
  margin-bottom: 4px;
  margin-top: 25px;
}

.familyHistorySummary {
  // display: block;
  font-size: 14px;
  color: #000000;
  margin-bottom: 4px;
  // min-height: 50px;
}

.obstetricHistory {
  color: #004d91;
  font-size: 18px;
  margin-bottom: 4px;
  // margin-top: 25px;
}

.top55 {
  margin-top: 55px;
}

.height30 {
  height: 30px;
}

.generalExam {
  display: flex;
  margin-top: 30px;
  margin-right: 120px;
}

.inputGeneralExam {
  width: 30px;
  height: 22px;
}

.marginLeft80 {
  margin-left: 80px;
}

.generalExamTitle {
  flex: 1;
  margin-top: 5px;
}

.vitalOp {
  margin-top: 15px;
  display: flex;
}

.vitalOpInner {
  flex: 1;
  text-align: right;
  padding-right: 10px;
  // width: 35px;
}

.vitalInput {
  height: 35px;
  margin-top: 10px;
  width: 180px;
  font-size: medium;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

.phyExamValue {
  height: 35px;
  // margin-top: 10px;
  margin-left: 10px;
  width: 180px;
  font-size: medium;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

.diagnosis {
  font-size: 18px;
  color: #004d91;
  margin-bottom: 4px;
  margin-top: -10px;
}

.diagnosisSummaryBox {
  display: flex;
  border: 1px solid #ccc;
  height: 195px;
}

.diagnosisSummaryBoxInner {
  flex: 1;
  margin-left: 10px;
  border-right: 1px solid #ccc;
  p {
    font-size: 15px;
    font-style: normal;
  }
}

.signAreaOp {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 8%;
  hr {
    margin-top: -1px;
  }
}

.top50 {
  margin-top: 50px;
}

.top10 {
  margin-top: 10px;
}

.height50 {
  display: inline-block;
  height: 50px;
}

.docUrl {
  margin-right: 253px;
  font-size: 12px;
}
.admin-signature {
  width: 100%;
}
.signature-container {
  flex: 1;
  position: relative;
}
.inactive {
  margin-top: 50px;
}
.signature-details {
  position: 'absolute';
  margin-top: -60%;
}
.followup-container {
  flex: 2;
  margin-top: '-8%';
}
.flexTwo {
  flex: 1;
  margin-top: 5%;
}
.finished {
  background-color: grey !important;
  color: white !important;
}
.opdvisit-opsheet {
  display: none;
}
