.text-right {
  text-align: right;
}

.m25 {
  margin: 25px;
}

* {
  box-sizing: border-box;
}

.blurredCompo {
  filter: blur(2px);
}

.skeleton label,
.skeleton div {
  background-image: linear-gradient(to top, transparent, #000),
    linear-gradient(to right, green, yellow, blue);
  background-size: 100% 100%, 2000% 100%;
  animation: move 5s infinite;
  width: 200px;
  height: 35px;
  display: inline-block;
}
.hideSkeleton {
  height: 0;
  display: none;
}

@keyframes move {
  from {
    background-position: center center, left center;
  }

  to {
    background-position: center center, right center;
  }
}

body {
  background-color: #fafafa;
}

.container {
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin: 25px;

  .label-legend {
    position: relative;

    h6 {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 6px;
      display: inline-block;
      font-size: 14px;
      color: #004c91;
      margin: 0;
      background-color: #fff;
      z-index: 1;
      position: relative;
      margin-left: 25px;
    }

    &::after {
      content: '';
      width: 100%;
      left: 0;
      position: absolute;
      border-bottom: 1px solid #ccc;
      top: 13px;
    }
  }

  .label-legend1 {
    h6 {
      padding: 5px 6px;
      display: inline-block;
      font-size: 14px;
      color: #004c91;
      margin: 0;
      background-color: #fff;
      margin-left: 25px;
      position: relative;

      img {
        position: absolute;
        left: -14px;
        width: 10px;
        top: 8px;
        transform: rotate(270deg);
        transition: 0.3s;

        &.expandedIcon {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.container .mainCont {
  width: 70%;
}

.fieldSet label {
  width: 30%;
  display: inline-block;
  text-align: right;
  color: #363463;
  font-size: 14px;
  padding-right: 10px;
  float: left;
  margin-top: 8px;
}

.fieldSet div {
  width: 70%;
  display: inline-block;

  input {
    width: 300px;
    border: 1px solid #ddd;
    padding: 2px 10px;
    margin: 5px 0 0;
    height: 25px;
  }

  input:disabled {
    opacity: 0.6;
  }

  select {
    width: 255px;
    border: 1px solid #ddd;
    padding: 2px 10px;
    margin: 5px 0 0;
    height: 25px;
    background-color: #fff;
  }

  textarea {
    width: 260px;
    border: 1px solid #ddd;
    padding: 2px 10px;
    margin: 5px 0 0;
    background-color: #fff;
    border-radius: 4px;
  }
}

.fieldSet input[type='radio'] {
  width: auto;
  margin: 0;
  vertical-align: middle;
}

.fieldSet input[type='file'] {
  width: auto;
}

.fieldSet::after {
  clear: both;
  content: '';
}

.required_red {
  color: red;
}

.outerlayer {
  border: 7px solid #eee;
  display: inline-block;
  height: 134px;
  width: 134px !important;

  img {
    width: 120px;
    height: 120px;
  }
}

.photoCameraUploadELe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 500px;
  z-index: 100;

  h4 {
    margin: 0;
    background-color: #004c91;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 15px;
  }
}

.profileContainer {
  position: absolute;
  right: 20px;
  top: 50px;

  .uploadImg {
    width: auto;
  }

  .fieldSet {
    .uploadIcons {
      width: auto;
      position: absolute;
      left: -44px;
      top: 4px;
    }

    span {
      width: 38px;
      height: 38px;
      display: block;
      background-color: #eee;
      margin-bottom: 6px;
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
}

.twoMainSides {
  display: flex;

  .mainCont {
  }
}

.otherInfo {
  margin-top: 20px;
  .label-legend {
    width: 90%;
    margin: 0 auto;

    h6 {
      font-weight: 400;
    }
  }
}

.formSaveBtn {
  background: linear-gradient(to bottom, #fff, #ddd);
  background-color: #ddd;
  border: 1px solid #ddd;
  padding: 9px 26px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}
.formSaveBtn:hover {
  background: linear-gradient(to bottom, #ddd, #fff);
}

.sxp-error-message {
  margin-left: 12px;
  font-style: italic;
  font-size: 11px;
  color: red;
}

.radio-container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}
.radio-container input[type='radio'] {
  appearance: none;
  display: none;
}
.radio-container label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  min-width: 80px;
  width: 125px;
  height: 34px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  transition: ease-in 150ms;
  color: #6e6e6edd;
  border: 1px solid #ddd;
  margin: 2px;
  padding: 2px 8px;
}
.radio-container input[type='radio']:checked + label {
  background-color: #4682B4;
  color: #f1f3f5;
  font-weight: 600;
  transition: 150ms;
}

.form-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(250, 250, 250, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload-container {
  margin-top: 20px;
  margin-left: -125px;
}

.upload-image {
  display: flex;
  justify-content: center;
}

.upload-image-button {
  display: flex;
  justify-content: center;
  margin-left: -200px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-url {
  display: flex;
  justify-content: center;
  margin-left: -135px;
  font-size: small;
  cursor: pointer;
}

.helper-text {
  display: flex;
  justify-content: center;
  margin-left: -145px;
  font-size: small;
}
