.lms-container {
  padding: 0.5rem;
  display: flex;
  overflow: hidden;
  height: auto;
}
.lms-container-div {
  padding: 0.5rem;
  display: flex;
  height: auto;
}
.datePickerUI {
  padding-left: 10px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  margin-right: 10px;
  width: 115px;
  height: 30px;
  background-color: transparent;
}
.summary-date {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 9px;
}
.start-date {
  margin-top: 7px;
  font-size: 12px;
}
.start-summary {
  display: flex;
  gap: 5px;
}

.lms-menu {
  flex: 0 0 200px;

  ul {
    padding-left: 0;
    margin: 0;
    background-color: #0d3f6d;

    li {
      list-style-type: none;
      border-bottom: 1px solid #e6e6e6;

      &:last-child {
        border-bottom: none;
      }

      a {
        font-size: 13px;
        color: #fff;
        text-decoration: none;
        padding: 8px;
        display: block;
        transition: 0.2s;

        &.active {
          background-color: #f8f8f8;
          color: #115797;
        }
      }
    }
  }
}

.lms-content {
  flex-grow: 1;
  overflow-y: auto;
}
.lms-content-div {
  flex-grow: 1;
}
.lms-status {
  margin: 0% 1%;
}

.admin-table {
  width: 100%;
  font-size: 13px;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid #004c91;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  th,
  td {
    padding: 6px 12px;
  }

  thead {
    background-color: #4682b4;
    color: #ffffff;
    border: 1px solid #e6e6e6;

    tr {
      text-align: left;

      th {
        font-weight: 500;
      }

      th:first-child {
        border-top-left-radius: 4px;
      }

      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody {
    .lms-btn {
      border: none;
      background-color: transparent;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
      text-align: left;
      padding: 0;

      &:focus-visible {
        outline: #0088ff auto 1px;
      }
    }

    tr:nth-child(even) {
      background-color: #ebebeb;
    }
  }

  .selected-patient {
    font-weight: 600;
  }
}
.test-group-table {
  width: 100%;
  border-radius: 8px;
}
.test-group-table,
.test-group-table tr,
.test-group-table td,
.test-group-table th {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}
.test-group-table {
  width: 100%;
  font-size: 13px;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid #004c91;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  th {
    padding: 6px 12px;
  }

  thead {
    background-color: #4682b4;
    color: #ffffff;
    border: 1px solid #e6e6e6;

    tr {
      text-align: left;

      th {
        font-weight: 500;
      }

      th:first-child {
        border-top-left-radius: 4px;
      }

      th:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  tbody {
    .lms-btn {
      border: none;
      background-color: transparent;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
      text-align: left;
      padding: 0;

      &:focus-visible {
        outline: #0088ff auto 1px;
      }
    }

    tr:nth-child(even) {
      background-color: #ebebeb;
    }
  }
}

.admin-header {
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 8px;
}

.admin-select {
  padding: 4px;
  width: 115px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  background-color: white;
  height: 30px;

  &:focus-visible {
    outline: #aeafb5 solid 1px;
  }
}
.bed-admin {
  display: flex;
  gap: 10px;
}
.p5 {
  padding-left: 5% !important;
}

.admin-btn {
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: #4682b4;
  color: #ffffff;
  margin-left: 12px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:focus-visible {
    outline: black auto 1px;
  }
}

.dashboard-tabs {
  display: flex;
  justify-content: space-between;
  margin: 4px 0 10px;

  .tab-group {
    padding-left: 10px;
    border-bottom: 1px solid #004c91;

    .tab-btn {
      padding: 5px 8px;
      margin-right: 10px;
      border: 1px solid #004c91;
      border-bottom: none;
      background-color: #ffffff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;

      &.active {
        background-color: #4682b4;
        color: #ffffff;
      }

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }

  .lms-filters {
    display: flex;
    justify-content: flex-end;
    flex: 1;

    select {
      border: 1px solid #aeafb5;
      border-radius: 4px;
      margin-right: 8px;
      background-color: #ffffff;
      font-size: 12px;
      padding: 0 4px;
    }

    input[type='search'] {
      flex: 1;
      max-width: 300px;
      font-size: 12px;
      padding: 4px 10px;
      margin-right: 6px;
      border: 1px solid #aeafb5;
      border-radius: 4px;
    }

    input::placeholder {
      font-size: 12px;
      font-style: italic;
    }
  }
}

.collect-samples {
  display: flex;
  flex-direction: column;
  .search-view {
    align-items: center;
    margin-bottom: 10px;
    gap: 2rem;
    display: flex;
    justify-content: flex-end;
  }
  .no-orders {
    display: flex;
    margin-top: 8px;
    align-items: flex-end;

    .new-order {
      margin-left: 12px;
      border: none;
      background-color: #004c91;
      color: #ffffff;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }

  .collect-header {
    margin: 2px 0;
    font-size: 14px;
    font-weight: 500;
  }
}

.patient-search {
  display: flex;
  padding-bottom: 7px;

  input[type='search'] {
    flex: 1;
    max-width: 300px;
    font-size: 12px;
    padding: 4px 10px;
    margin-right: 6px;
    border: 1px solid #aeafb5;
    border-radius: 4px;
    height: 30px;
  }

  input::placeholder {
    font-size: 12px;
    font-style: italic;
  }

  .search-btn {
    padding: 4px 8px;
    background-color: #004c91;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:focus-visible {
      outline: black auto 1px;
    }
  }
}

.search-error {
  font-size: 11px;
  font-style: italic;
  color: red;
  margin: 0 0 8px 6px;
}
.search-error-laborder {
  font-size: 11px;
  font-style: italic;
  color: red;
  margin: 0 0 8px 6px;
  display: flex;
  justify-content: end;
  margin-right: -30px;
}
.search-error-filtertest {
  display: flex;
  justify-content: end;
  margin-right: 90px;
}

.patient-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.lab-container {
  display: flex;
  width: 100%;
}

.back-btn {
  margin: 8px 0;
  border: 1px solid #004c91;
  border-radius: 4px;
  background-color: #e6e6e6;
  color: #121212;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 8px 2px 0;
}

.collect-order {
  .pat-gender {
    text-transform: capitalize;
  }
  .collect-approval {
    margin-top: 7px;
    margin-left: 53% textarea {
      resize: none;
    }
    .approval-buttons {
      display: flex;
      gap: 8px;
    }
  }
}

.admin-config {
  display: flex;
  justify-content: flex-end;

  input[type='search'] {
    flex: 1;
    max-width: 300px;
    font-size: 12px;
    padding: 4px 10px;
    margin-left: 12px;
    border: 1px solid #aeafb5;
    border-radius: 4px;
  }

  input::placeholder {
    font-size: 12px;
    font-style: italic;
  }
}

.tests-row {
  display: flex;

  .collect-tests {
    margin-top: 12px;
    min-width: 300px;

    .collect-tests-header {
      display: flex;
      justify-content: space-between;

      span {
        display: flex;
        align-items: baseline;
      }

      #select-all-samples {
        margin-right: 6px;
      }
    }

    .single-test {
      display: flex;
      width: 100%;
      padding: 2px 6px;

      .select-box {
        flex-shrink: 0;
        flex-grow: 0;

        input[type='checkbox']:focus-visible {
          outline: black auto 1px;
        }
      }

      .test-content {
        flex: 1;
        margin-left: 12px;
        font-size: 13px;

        .collected-by {
          margin-left: 8px;
          color: #808080;
          font-size: 12px;
          font-style: italic;
        }
      }
    }

    .save-btn {
      font-size: 13px;
      background-color: #004c91;
      color: #ffffff;
      border: none;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }

  .source-info {
    margin: 12px 0 0 60px;
    font-size: 13px;
  }

  .collection-status {
    margin-top: 12px;
    font-size: 13px;

    .event-status {
      display: flex;
      padding: 6px;
      background-color: #ebebeb;
      border-radius: 4px;
      margin-bottom: 8px;
      align-items: baseline;
    }

    .event-date {
      margin-left: 6px;
      font-size: 11px;
      font-style: italic;
    }

    .rejected {
      color: #ab1212;
      font-weight: bold;
    }
  }
}

.collect-button {
  padding: 4px 8px;
  font-size: 13px;
  background-color: #004c91;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 2px;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &:focus-visible {
    outline: black auto 1px;
  }
}

.all-collected {
  display: block;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.toast-inner {
  font-size: 14px;
}

.patient-name {
  text-transform: capitalize;
}

.result-actions {
  display: flex;
  margin: -2px;

  button {
    margin-right: 4px;
    cursor: pointer;
    padding: 2px;
    border: none;
    background-color: transparent;

    &:focus-visible {
      outline: black auto 1px;
    }

    img {
      width: 20px;
    }
  }

  .auth-disabled {
    cursor: not-allowed;
  }

  .validation-pending {
    background-color: rgba(226, 51, 51, 0.4);
    border-radius: 3px;
  }
}

.result-label {
  display: flex;
  flex-direction: column;
  padding: 6px;
  max-width: 184px;
  font-size: 12px;
  font-weight: 500;

  .name-row {
    display: flex;
    justify-content: space-between;
  }

  .id-row {
    display: flex;
    justify-content: space-between;
    font-size: 9px;
  }

  .code-row {
    display: flex;
    justify-content: center;
  }
}

.packages-block {
  display: flex;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 13px;
      font-weight: 500;
    }
  }

  input {
    padding: 2px;
    border: 1px solid #808080;
    border-radius: 4px;
    margin-right: 6px;
  }
}

.sequences-container {
  display: flex;

  .sequences-menu {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
  }

  .sequences-content {
    flex: 1;

    .sequence-item {
      width: 320px;
      border: 1px solid #808080;
      border-radius: 3px;
      margin-bottom: 2px;
      padding: 5px;
      font-size: 13px;
      font-family: monospace;
      vertical-align: center;
    }
  }
}

.validate-results {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .result-summary {
    margin-bottom: 8px;
    padding: 6px;
  }
}

.results-section {
  font-size: 13px;

  .test-group-actions {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}

.test-group {
  display: flex;
  background-color: #b1b6bb;
  color: #f1f1f1;
  border-radius: 6px 6px 2px 2px;
  padding: 5px;
  margin: 4px 0 2px;
  // justify-content: space-between;

  .test-name {
    display: flex;
    font-size: 12px;
    font-family: sans-serif;
    font-weight: 600;
    margin: 0;
    margin-right: 30px;
    color: black;
    // flex: 1;
    align-items: center;

    h3 {
      margin-left: 4px;
    }
  }

  .referred {
    display: flex;
    align-items: center;
    // margin-left: 12px;
    //  flex-basis: 300px;

    input[type='checkbox'] {
      &:focus-visible {
        outline: black auto 1px;
      }
    }

    span {
      margin-left: 6px;
    }

    select {
      margin-left: 6px;
      padding: 1px;

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }

  .documents {
    display: flex;
    flex-basis: 150px;
    max-width: 150px;
    //  align-items: baseline;
    input::file-selector-button {
      height: 24px;
    }

    a {
      color: white;
    }

    a,
    p {
      margin: 0 0 0 4px;
    }
  }
}
.test-group > div {
  border-right: 1px solid white;
  // padding-right: 10px; /* Optional: To add some space between the border and content */
}

/* Optional: To handle the last child not having a border-right */
.test-group > div:last-child {
  border-right: none;
}
.ddd {
  display: flex;
  align-items: center;
  width: 14%;
  // justify-content: space-around;
}
.test-row {
  display: flex;
  border: 1px solid rgba(70, 70, 70, 0.2);
  padding: 6px;
  margin-bottom: 2px;
  border-radius: 2px;

  .test-details {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    flex-basis: 350px;

    .test-name {
      font-weight: 500;
    }

    .limit-details {
      font-size: 12px;
      color: #4d4d4d;
      padding-top: 2px;
    }

    .rejected-info {
      font-weight: 600;
      font-size: 14px;
      color: #892323;

      .rejected-date {
        font-size: 12px;
        font-style: italic;
      }
    }
  }

  .value-input {
    display: flex;
    flex-basis: 350px;
    align-items: flex-start;
    margin-left: 6px;

    input[type='number'] {
      max-width: 80px;
    }

    .abnormal {
      border: 2px solid red;
      background-color: #eeff9e;
    }

    span {
      margin-left: 4px;
      font-size: 12px;
    }

    .extra-value {
      margin-left: 8px;
    }
  }

  .notes {
    display: flex;
    flex-basis: 350px;
    flex-direction: column;

    button {
      align-self: flex-start;
      margin-bottom: 4px;
    }
  }

  .notes-area {
    resize: none;
    padding: 4px;
  }
}
.noted-area {
  width: 100%; /* Ensures the textarea takes full width */
  resize: none; /* Disable manual resizing */
  border: 1px solid #ccc; /* Keep the border consistent */
}

.rejected-test {
  background-color: #f2cdcd;
}

.referred-search {
  margin-bottom: 6px;
  width: 300px;
  padding: 5px 10px;
  border: 1px solid #aeafb5;
  border-radius: 4px;
  background-color: transparent;
  background: white;

  &::placeholder {
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
  }
}

.observation-container {
  display: flex;
  flex-direction: column;

  .lms-observation-new {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: 4px;
    }

    .obs-area {
      resize: none;
    }

    .input-error {
      border: 2px solid red;
    }

    .error-text {
      color: red;
      font-size: 12px;
      font-style: italic;
      margin: 0 0 4px;
    }

    .lms-observation-btn {
      padding: 3px 6px;
      font-size: 13px;
      background-color: #004c91;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      align-self: flex-start;

      &:hover {
        opacity: 0.9;
      }

      &:focus-visible {
        outline: black auto 1px;
      }
    }
  }

  .observation-list {
    display: flex;
    flex-direction: column;
    margin-left: 6px;

    & > * {
      margin-bottom: 4px;
    }

    .single-observation {
      position: relative;
      padding: 6px;
      border-radius: 4px;

      &.selected {
        border-width: 2px;
      }

      .obs-delete {
        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 11px;
        font-weight: bold;
        background-color: #232323;
        color: white;
        border-radius: 50%;

        &:disabled {
          cursor: default;
          opacity: 0.4;
        }
      }

      .obs-title {
        font-size: 13px;
        font-weight: 500;
      }

      .obs-content {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}

.patient-id {
  font-weight: 500;
  cursor: pointer;
}

.event-dialog {
  height: 600px;
  width: 500px;
  padding: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .event-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    .close-img {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }
  }

  .event-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 12px;
    padding: 16px;
    border-bottom: 1px solid rgba(57, 57, 57, 0.2);

    h2 {
      font-size: 15px;
      font-weight: 500;
      margin: 0;
    }

    p {
      margin: 4px 0 0;

      span {
        color: #565656;
        font-weight: 700;
      }
    }

    .patient-details {
      text-align: right;
    }
  }

  .events {
    overflow-y: auto;

    .single-event {
      display: flex;

      .event-left {
        width: 180px;
        padding: 4px 18px 4px 4px;
        margin: 8px 12px 24px 6px;
        text-align: right;
        border-right: 1px solid rgba(57, 57, 57, 0.2);

        p {
          margin: 0;
        }

        .event-date {
          font-weight: 700;
          color: #565656;
        }

        .updated-by {
          text-transform: uppercase;
          font-style: italic;
        }
      }
    }
  }
}

.admin-table-container {
  overflow: auto;
}

.fixTableHead {
  overflow: auto;
}

.appointments-table-container {
  overflow: auto;
}

.collection-table-container {
  overflow: auto;
}

.collected-table-container {
  overflow: auto;
}

.backlog {
  margin-top: 10px;
}

.interpretation {
  padding: 20px;
}

.admin-lab-searchbox {
  margin-right: 6px;
  width: 320px;
  height: 30px;
}
.w2 {
  width: 2%;
}
.w3 {
  width: 3%;
}
.w4 {
  width: 4%;
}
.w5 {
  width: 5%;
}
.w6 {
  width: 6%;
}
.w7 {
  width: 7%;
}
.w30 {
  width: 30%;
}
.w75 {
  width: 75% !important;
}
.w84 {
  width: 84% !important;
}
.w8 {
  width: 8%;
}
.w9 {
  width: 9%;
}
.w10 {
  width: 10%;
}
.w18 {
  width: 18%;
}
.w16 {
  width: 16%;
}
.w25 {
  width: 25%;
}
.w33 {
  width: 33%;
}
.w34 {
  width: 34%;
}
.w35 {
  width: 35%;
}
.w40 {
  width: 40%;
}
.w43 {
  width: 43%;
}
.w54 {
  width: 54%;
}
.w55 {
  width: 55%;
}
.w72 {
  width: 72%;
}
.w100 {
  width: 100%;
}
.w120 {
  width: 130%;
}
.m10 {
  margin-left: 10%;
}
.summary {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.overall-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.position-top {
  position: 'sticky';
  top: '0px';
}
.download-action {
  margin-top: -8px;
  display: flex;
  justify-content: space-between;
}
.action {
  font-size: 11px;
  padding: 4px 13px !important;
  width: 61px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  height: 24px;
  display: flex;
  color: #1976d2;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  background-color: #d1d2db;
  border: none;
  border-radius: 4px;
}
.approve {
  display: flex;
  gap: 8px;
  margin-top: 6px;
}
.pathology-input {
  padding: 5px 8px;
  border: none;
}
.range-column {
  display: flex;
  flex-direction: column;
}

.whatapp-message {
  padding: 10px;
}

.whatsapp-text {
  color: #004c91;
}

.number-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.send-whatsapp-btn {
  padding: 3px 12px !important;
  border-radius: 5px;
  background-color: #4682b4 !important;
}
.pdfText {
  color: #4682b4;
  font-size: 12px;
}
.fs12 {
  font-size: 12px;
}
.pdf-upload-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.handle-trimming {
  margin-left: 10px;
}
.header-details-option {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
}
.createvisitbutton {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.view-visit-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
}
.view-visit-button:disabled {
  color: rgb(197, 197, 240);
  cursor: not-allowed;
  text-decoration: underline;
  text-decoration: none;
}
.mr295 {
  margin-right: 295px !important;
}
.obs-delete {
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  background-color: #232323;
  color: white;
  border-radius: 50%;

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
}
.observation-flex {
  display: flex;
  gap: 10px;
  margin-left: -20px !important;
  margin-bottom: 10px !important;
}
.h30px {
  height: 30px !important;
}
.h29px {
  height: 29px !important;
}
.w60px {
  width: 60px !important;
}
.w165px {
  width: 165px;
}
.w380px {
  width: 380px;
}
.obs-flex {
  display: flex;
  margin-left: 67px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.obs-container {
  border: 1px solid rgba(76, 76, 76, 0.6);
  margin-right: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 6px;
  border-color: none;
}
.obs-span-title {
  font-size: 13px;
  font-weight: 500;
  word-break: break-word;
}
.obs-span-content {
  font-size: 12px;
  font-weight: 300;
  border-color: none;
  word-break: break-word;
}
.summary-container {
  margin-left: -45px;
  display: flex;
  gap: 5px;

  .summary-span-container {
    font-size: 14px;
    font-weight: 500;
    margin: 2px 0px;
  }
}
.dropdown-bottom {
  top: 4px;
  right: 420px;
}
.mtminus6 {
  margin-top: -6px;
}
.admin-header-text {
  color: #090968;
  font-size: 15px;
  font-weight: 600;
}
.mtminus29 {
  margin-top: -29px;
}
.refference-header {
  display: flex;
  justify-content: space-between;
}
.mt9 {
  margin-top: 9px;
}
.mt10 {
  margin-top: 10px !important;
}
.ml10 {
  margin-left: 10px;
}
.select-mode {
  height: 30px;
  width: 122px;
  border-radius: 5px;
  background: none;
  border-color: #aeafb5;
}
.package-container {
  display: flex;
}
.mt5 {
  margin-top: 5px;
}
.trim-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.trim-text {
  font-size: 14px;
  font-weight: 600;
  color: #363463;
  text-align: center;
}
.validation-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}
.collection-content-select {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.collection-content {
  display: flex;
  gap: 10px;
}
.collect-content {
  display: flex;
  flex-direction: row;
  margin-left: 66%;
}
.border-none {
  border: none;
}
.display-none {
  display: none;
}
.mb25 {
  margin-bottom: 25px !important;
}
.backspace-icon-search-view {
  font-size: 20;
  color: blue;
  margin-top: -8px;
}
.lab-create-btn {
  text-transform: none !important;
  background-color: #4682b4;
}
.back-btn-lab {
  font-size: 20px;
  color: blue;
  margin-top: -8px;
}
.referred-one {
  width: 35%;
  display: flex;
  gap: 20px;
}
.submitted {
  width: 25%;
  display: flex;
  justify-content: end;
  gap: 20px;
}
.test-flex {
  width: 30%;
  display: flex;
  justify-content: end;
  gap: 20px;
}
.referredOne {
  display: flex;
  width: 23%;
  justify-content: space-between;
  align-items: center;
}
.refer-documents {
  display: flex;
  width: 38%;
  align-items: center;
}
.refer {
  width: 46% !important;
}
.refer-one {
  width: 43% !important;
}
.refer-two {
  width: 25%;
}

.observation-input {
  margin-left: 27%;
  overflow: hidden;
  // position: relative; /* Ensures that the textarea's overflow doesn't affect surrounding elements */
  // border: 1px solid #ccc; /* Border for the container, if needed */
  // padding: 5px; /* Add some padding to ensure space around the textarea */
  // overflow: hidden;
}
.interpretation-container {
  display: flex;
  flex-direction: column;
}
.mt7 {
  margin-top: 7px !important;
}
mt3 {
  margin-top: 3px;
}
.mlminus30 {
  margin-left: -30px;
}
.expand-content {
  width: 33%;
  display: flex;
}
.status-content {
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
}
.referred-expand-content {
  width: 50%;
  display: flex;
}
.check-content {
  display: flex;
  width: 35%;
  justify-content: space-around;
  align-items: center;
}
.referred-check-content {
  display: flex;
  width: 35%;
  justify-content: space-around;
  align-items: center;
}
.referred-image-container {
  display: flex;
  // width: 12%;
  align-items: center;
  // justify-content: flex-end
}
.referred-image-content {
  width: 10%;
  margin-left: 50px;
}
.upload-image-container {
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: flex-end;
}
.pointer {
  cursor: pointer;
}
.blueColor {
  color: blue;
}
.referred-text {
  white-space: nowrap;
  margin-right: 22px;
}
.submitted-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
.panel-admin {
  display: flex;
  gap: 10px;
}
.mw220 {
  min-width: 220px;
}
.mw20 {
  min-width: 20px;
}
.no-wrap {
  white-space: nowrap;
}
.auto-height {
  height: auto;
}
.hidden-overflow {
  overflow: hidden;
}
.test-group-container {
  display: flex;
  justify-content: space-between;
}
.p7 {
  padding: 7px !important;
}
.p0 {
  padding: 0px !important;
}
.table-overflow-y {
  overflow-y: auto;
}
.word-break-content {
  word-wrap: break-word;
}
.text-black {
  color: black;
}
.downloadSummary {
  font-size: 11px;
  padding: 4px 13px !important;
  width: 61px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  height: 24px;
  display: flex;
  color: #1976d2;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  background-color: #d1d2db;
  border: none;
  border-radius: 4px;
}

.linkbtns {
  color: blue;
  cursor: pointer;
}
.labTestParameter {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.isodoocheckbox {
  margin-right: 1rem;
}
.LabTestRefference {
  margin-top: 3.5rem;
}
.LabTestRefferenceContainer {
  margin-top: 2.5rem;
}
.Labreferred {
  display: flex;
  gap: 10;
  border: none !important;
}
.clickable-cell {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.package-head-content {
  position: sticky;
  top: 0px;
}
.test-submit-btn {
  width: 10%;
}
.lab-order-create-btn {
  text-transform: capitalize !important;
  background-color: #4682b4 !important;
}
