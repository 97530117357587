.menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 800px;
}

.menu-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    -3px 5px 3px 1px rgba(0, 0, 0, 0.2);
  padding: 15px 10px;
  width: 116px;
  height: auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #4682b4;
}

.menu-card-image {
  fill: #fff !important;
  width: 35px;
  height: 35px;
  align-self: center;
  filter: drop-shadow(-15px 15px 8px rgb(3, 3, 3));
}

.menu-card-details {
  margin-top: 10px;
  text-align: center;
}

.menu-card-name {
  font-size: 0.8rem;
  color: #fff;
  margin: 0;
  font-weight: 400;
}
.content-height {
  background-repeat: round;
  height: 97vh;
}
